<template>
  <div>
    <Header ref="header"/>

    <div class="content article-content">
      <div style="height: 20px"></div>
      <div class="w w1200 clearfix">
        <table>
          <tr>
            <th>奖品</th><th>类型</th><th>消耗金额</th><th>抽奖时间</th>
          </tr>
          <tr v-for="(item, index) in list" v-bind:key="index">
            <td>{{ item.goodsName == null ? '未中奖' : item.goodsName }}</td><td>{{ item.luckDrawType }}</td><td>{{ item.usedAmount }}</td><td>{{ item.createTime }}</td>
          </tr>
        </table>
      </div>

    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import {getList} from "@/api/mall/marketing/lottery/luckDrawLog";

export default {
  name: "index",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      list:[]
    }
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      getList().then(res => {
        this.list = res.data
        console.log(res)
      })
    }
  }
}
</script>

<style scoped>
table
{
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
table td, table th
{
  border: 1px solid #cad9ea;
  color: #666;
  height: 30px;
}
table thead th
{
  background-color: #CCE8EB;
}
table tr:nth-child(odd)
{
  background: #fff;
}
table tr:nth-child(even)
{
  background: #F5FAFA;
}
</style>
